// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-products-js": () => import("./../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-services-js": () => import("./../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-404-js": () => import("./../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

